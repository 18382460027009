import React from 'react';
import PropTypes from 'prop-types';
import MESSAGES from '@messages';

const CartClassesCell = ({
  name = '',
  day_index = 0,
  instructor = '',
  start_time = '',
  end_time = '',
  month,
}) => (
  <>
    <div>
      <strong>{name}</strong>
      {typeof month === 'number' ? ` (${MESSAGES.MONTH_NAMES[month]})` : ''}
    </div>
    <div>
      {`${MESSAGES.DAY_NAMES[day_index]} ${start_time} - ${end_time}`}
    </div>
    {instructor && (
      <div>
        {MESSAGES.INSTRUCTOR}
        {': '}
        {instructor}
      </div>
    )}
  </>
);

CartClassesCell.propTypes = {
  name: PropTypes.string,
  day_index: PropTypes.number,
  month: PropTypes.number,
  instructor: PropTypes.string,
  start_time: PropTypes.string,
  end_time: PropTypes.string,
};

export default CartClassesCell;
