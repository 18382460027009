import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MESSAGES from '@messages';
import CartClassesCell from './CartClassesCell';

const Table = styled.table`
  width: 100%;
  font-size: 0.8em;
  margin-bottom: 1em;
`;

CartClassesCell.propTypes = {
  name: PropTypes.string,
  day_index: PropTypes.number,
  month: PropTypes.number,
  instructor: PropTypes.string,
  start_time: PropTypes.string,
  end_time: PropTypes.string,
};

const CartTable = ({
  items,
  participants,
}) => (
  <>
    <Table>
      <thead>
        <tr>
          <th>{MESSAGES.CLASS}</th>
          <th>{MESSAGES.PARTICIPANT}</th>
          <th>{MESSAGES.CLASSES_IN_MONTH}</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => {
          const { name: participantName } = participants.find((p) => p.id === item.participant) || {};
          const { events_length } = item;

          const eventsLength = events_length || 0;

          return (
            <tr key={`${item.id}-${item.participant}`}>
              <td>
                <CartClassesCell {...item} />
              </td>
              <td>{participantName}</td>
              <td>
                {eventsLength}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  </>
);

CartTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  participants: PropTypes.arrayOf(PropTypes.object),
  amount: PropTypes.number,
  voucher: PropTypes.shape({}),
};

export default CartTable;
