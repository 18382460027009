// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-aktywacja-konta-jsx": () => import("./../src/pages/aktywacja-konta.jsx" /* webpackChunkName: "component---src-pages-aktywacja-konta-jsx" */),
  "component---src-pages-harmonogram-jsx": () => import("./../src/pages/harmonogram.jsx" /* webpackChunkName: "component---src-pages-harmonogram-jsx" */),
  "component---src-pages-podsumowanie-jsx": () => import("./../src/pages/podsumowanie.jsx" /* webpackChunkName: "component---src-pages-podsumowanie-jsx" */),
  "component---src-pages-reset-hasla-jsx": () => import("./../src/pages/reset-hasla.jsx" /* webpackChunkName: "component---src-pages-reset-hasla-jsx" */),
  "component---src-templates-about-page-jsx": () => import("./../src/templates/about-page.jsx" /* webpackChunkName: "component---src-templates-about-page-jsx" */),
  "component---src-templates-contact-page-jsx": () => import("./../src/templates/contact-page.jsx" /* webpackChunkName: "component---src-templates-contact-page-jsx" */),
  "component---src-templates-faq-page-jsx": () => import("./../src/templates/faq-page.jsx" /* webpackChunkName: "component---src-templates-faq-page-jsx" */),
  "component---src-templates-home-page-jsx": () => import("./../src/templates/home-page.jsx" /* webpackChunkName: "component---src-templates-home-page-jsx" */),
  "component---src-templates-offer-page-jsx": () => import("./../src/templates/offer-page.jsx" /* webpackChunkName: "component---src-templates-offer-page-jsx" */),
  "component---src-templates-text-page-jsx": () => import("./../src/templates/text-page.jsx" /* webpackChunkName: "component---src-templates-text-page-jsx" */)
}

