// :API_URL = 'http://31.186.83.240:1337',
// export const API_URL = 'https://api.centrumakrobatyczne.pl';
// export const V2_API_URL = 'https://v2.api.centrumakrobatyczne.pl';
export const V2_API_URL = 'https://api.v2.centrumakrobatyczne.pl';
export const LOCAL_API_URL = 'http://localhost:1111/';

const getApiUrl = () => {
  if (process && process.env && process.env.GATSBY_IS_LOCAL) return LOCAL_API_URL;
  return V2_API_URL;
};

export const API_URL = getApiUrl();

export const COOKIES_ACCEPTED_KEY = 'cookies_accepted';

export const URLS_WITH_USER_ACTIONS = [
  'harmonogram',
  'podsumowanie',
];

export const PAYMENT_STATUSES = {
  START: 'rozpoczete',
  COMPLETED: 'sukces',
  REJECTED: 'anulowane',
  WRONG: 'niepoprawne',
  OUTDATED: 'przedawnione',
};

export const DAY_NAMES = ['niedziele', 'poniedziałki', 'wtorki', 'środy', 'czwartki', 'piątki', 'soboty'];
export const DAY_NAMES_SHORT = ['NIE', 'PON', 'WT', 'ŚR', 'CZW', 'PT', 'SOB'];
export const MONTH_NAMES = ['styczeń', 'luty', 'marzec', 'kwiecień', 'maj', 'czerwiec', 'lipiec', 'sierpień', 'wrzesień', 'październik', 'listopad', 'grudzień'];


export const VOUCHERS_ERRORS = {
  'voucher.not_found': 'Kod jest nieprawidłowy',
  'voucher.wrong_client': 'Nie jesteś uprawniony/a do skorzystania z kodu',
  'voucher.used': 'Voucher został już wykorzystany',
};

export const TECHNICAL_BREAK = false;
