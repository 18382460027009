import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import theme from '../styled/theme';
import CalendarCell from './CalendarCell';
import Event from './Event';

const Wrapper = styled(CalendarCell)`
    display: flex;
    position: relative;
    border-top: ${theme.colors.border} solid 1px;
    border-right: ${theme.colors.border} solid 1px;

    ${(props) => props.weekend && `
      background: ${theme.colors.lightBackground};
    `}

    ${(props) => props.timeSpacing && `
      flex: 0;
      border-right: none;
    `}
`;

const EventCell = (props) => {
  const { items, isNearRight } = props;

  let eventsComponent = null;

  if (items) {
    eventsComponent = items.map(({ event, events }) => {
      const {
        start_time, end_time, id, blockSpan,
      } = event || {};

      const height = `calc((${theme.calendar.height} * ${blockSpan}) - ${theme.calendar.eventPadding})`;

      return (
        <Event
          key={`${start_time}-${end_time}-${id}`}
          style={{ height }}
          event={event}
          events={events}
          isNearRight={isNearRight}
        />
      );
    });
  }

  return (
    <Wrapper {...props}>
      {eventsComponent}
    </Wrapper>
  );
};

EventCell.propTypes = {
  isNearRight: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({})),
};

export default EventCell;
