import React, { useState, useRef, useContext } from 'react';
import PropTypes from 'prop-types';
import { emailValidator, FormService } from '@loform/react';
import styled from 'styled-components';
import {
  TextInput, Checkbox, StaticSuccess,
} from '@components';
import MESSAGES from '@messages';
import { UserContext } from '@helpers';
import Form from './Form';

const errorsMap = {
  identifier_or_password_invalid: MESSAGES.WRONG_EMAIL_OR_PASSWORD,
  account_blocked: MESSAGES.ACCOUNT_BLOCKED,
  account_not_activated: MESSAGES.ACCOUNT_NOT_ACTIVATED,
  account_doesnt_exist: MESSAGES.ACCOUNT_DOESNT_EXIST,
  client_not_found: MESSAGES.NOT_FOUND,
};

const CheckboxesWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;


const LoginForm = ({ closePortal }) => {
  const [wasOpen, setOpen] = useState(false);
  const { login, isLogged } = useContext(UserContext);

  const formService = useRef(new FormService());

  const delayedClose = () => setTimeout(closePortal, 1000);

  return (
    <Form
      url="/login"
      title={MESSAGES.LOGGING_IN}
      replaceContent={!wasOpen && isLogged
        ? () => {
          delayedClose();
          return <StaticSuccess>{MESSAGES.YOU_ARE_ALREADY_LOGGED_IN}</StaticSuccess>;
        }
        : null}
      onSuccess={({ data, setMessage, values }) => {
        setOpen(true);
        const { jwt, ...rest } = data;
        if (jwt) {
          setMessage(
            { success: MESSAGES.SUCCESSFULLY_LOGGED_IN },
            () => {
              login({ jwt: data.jwt, ...rest }, values.remember);
              delayedClose();
            }
          );
        } else if (values.forgot_password) {
          setMessage({ success: MESSAGES.FORGOT_PASSWORD_EMAIL_SENT });
        }
      }}
      errorsMap={errorsMap}
      formService={formService.current}
    >
      {({ errors, setButtonText }) => {
        const formValues = formService.current.getValuesFromInputs();
        const { forgot_password } = formValues;
        setButtonText(forgot_password ? MESSAGES.SEND : MESSAGES.LOGIN);

        return (
          <>
            <TextInput
              errors={errors}
              name="identifier"
              placeholder={MESSAGES.EMAIL_ADDRESS}
              validators={[emailValidator(MESSAGES.WRONG_EMAIL)]}
              required
            />
            {!forgot_password && (
              <TextInput
                errors={errors}
                name="password"
                placeholder={MESSAGES.PASSWORD}
                required={!forgot_password}
                type="password"
              />
            )}
            <CheckboxesWrapper>
              <Checkbox
                errors={errors}
                name="forgot_password"
                label={MESSAGES.FORGOT_PASSWORD}
                inline
              />
              {!forgot_password && (
                <Checkbox
                  errors={errors}
                  name="remember"
                  label={MESSAGES.REMEMBER_ME}
                  inline
                />
              )}
            </CheckboxesWrapper>
          </>
        );
      }}
    </Form>
  );
};

LoginForm.propTypes = {
  closePortal: PropTypes.func,
};

export default LoginForm;
