import React, {
  useCallback, useMemo, useRef, useReducer, useEffect, useContext,
} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  auth, getCart, CartContext, CalendarContext, UserContext, cartItemHasPaidParticipants,
} from '@helpers';

const UNDO_CART_TIMEOUT = 15;

const INITIAL_STATE = {
  undoTimeLeft: 0,
  isLoadingVoucher: false,
  voucher: {},
  cartItems: [],
};

const ACTIONS = {
  LOADING_VOUCHER: 'loading-voucher',
  SET_TIMEOUT: 'set-timeout',
  SET_VOUCHER: 'set-voucher',
  SET_CART: 'set-cart',
  FORCE_CLEAR_CART: 'force-clear-cart',
  REMOVE_CART_ITEM: 'remove-cart-item',
};

function reducer(state, {
  type, voucher = {}, cartItems = [], undoTimeLeft = 0,
}) {
  switch (type) {
  case ACTIONS.LOADING_VOUCHER:
    auth.clearVoucher();
    return { ...state, voucher: {}, isLoadingVoucher: true };

  case ACTIONS.SET_VOUCHER:
    auth.setVoucher(voucher.code);
    return { ...state, voucher, isLoadingVoucher: false };

  case ACTIONS.CLEAR_VOUCHER:
    auth.clearVoucher();
    return { ...state, voucher: {}, isLoadingVoucher: false };

  case ACTIONS.SET_CART:
    auth.setCart({ cartItems });
    return { ...state, cartItems, undoTimeLeft: 0 };

  case ACTIONS.REMOVE_CART_ITEM:
    return { ...state, cartItems, undoTimeLeft: UNDO_CART_TIMEOUT };

  case ACTIONS.FORCE_CLEAR_CART:
    auth.clearVoucher();
    auth.clearCart();
    return {
      ...state, voucher: {}, cartItems: [], isLoadingVoucher: false, undoTimeLeft: UNDO_CART_TIMEOUT,
    };

  case ACTIONS.SET_TIMEOUT:
    return { ...state, undoTimeLeft: undoTimeLeft > 0 ? undoTimeLeft : 0 };

  default:
    throw new Error();
  }
}

const CartProvider = ({ children }) => {
  const undoTimeout = useRef();
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const { flattenEvents = [] } = useContext(CalendarContext);
  const {
    prices, isLogged, upcommingMonthgroups, isSuperUser,
  } = useContext(UserContext);

  const setVoucher = (voucher) => dispatch({ type: ACTIONS.SET_VOUCHER, voucher });

  const applyVoucher = useCallback((code) => {
    dispatch({ action: ACTIONS.LOADING_VOUCHER });
    axios.get(`/vouchers/${code}`)
      .then(({ data }) => {
        setVoucher(data);
      })
      .catch((err) => {
        dispatch({ type: ACTIONS.CLEAR_VOUCHER });
        return err;
      });
  }, []);

  const checkCartItem = useCallback(
    ({ id, participant }) => state.cartItems
      .some((item) => item.id === id && item.participant === participant),
    [state.cartItems]
  );

  const checkPaidItem = useCallback(({ id, participant }) => {
    const foundEvent = flattenEvents.find((event) => id === event.id);
    return cartItemHasPaidParticipants({ id, participant }, foundEvent);
  }, [flattenEvents]);

  const addToCart = useCallback((nextData) => {
    const nextItems = Array.isArray(nextData) ? nextData : [nextData];
    const cannotChange = !nextItems.length
      || nextItems.some((item) => checkCartItem(item, state.cartItems));

    if (!cannotChange) {
      dispatch({
        type: ACTIONS.SET_CART,
        cartItems: [...state.cartItems, ...nextItems],
        undoTimeLeft: 0,
      });
    }
  }, [checkCartItem, state.cartItems]);

  const setSavedCart = () => {
    const { cartItems } = auth.getCart() || {};
    dispatch({ type: ACTIONS.SET_CART, cartItems });
  };

  const removeFromCart = useCallback(({ id, participant }) => {
    const nextCartItems = state.cartItems
      .filter((item) => !(item.id === id && item.participant === participant));

    dispatch({ type: ACTIONS.REMOVE_CART_ITEM, cartItems: nextCartItems });
  }, [state.cartItems]);

  const forceClearCart = useCallback(() => dispatch({ type: ACTIONS.FORCE_CLEAR_CART }), [dispatch]);

  useEffect(() => {
    if (state.undoTimeLeft <= 0) {
      clearTimeout(undoTimeout.current);
    } else {
      undoTimeout.current = setTimeout(() => {
        const nextTimeout = state.undoTimeLeft - 1;

        if (nextTimeout <= 0) {
          auth.setCart({ cartItems: state.cartItems });
        }
        dispatch({ type: ACTIONS.SET_TIMEOUT, undoTimeLeft: nextTimeout });
      }, 1000);
    }
    return () => clearTimeout(undoTimeout.current);
  }, [state.undoTimeLeft, state.cartItems]);

  const { items, amount } = useMemo(
    () => getCart({
      flattenEvents,
      cartItems: state.cartItems,
      prices,
      voucher: state.voucher,
      upcommingMonthgroups,
    }),
    [flattenEvents, state.cartItems, prices, state.voucher, upcommingMonthgroups]
  );

  useEffect(() => {
    if (!isLogged) return;
    if (isSuperUser) auth.clearCart();
    setSavedCart();

    const code = auth.getVoucher();
    if (!code) return;
    applyVoucher(code);
  }, [applyVoucher, isLogged]);

  return (
    <CartContext.Provider
      value={{
        ...state,
        items,
        amount,
        addToCart,
        removeFromCart,
        forceClearCart,
        checkItem: checkCartItem,
        checkPaidItem,
        undoCart: setSavedCart,
        setVoucher,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

CartProvider.defaultProps = {
  pageconfig: {},
};

CartProvider.propTypes = {
  pageconfig: PropTypes.shape({
    show_events_from: PropTypes.instanceOf(Date),
  }),
  children: PropTypes.elementType,
};

export default CartProvider;
