import React from 'react';
import PropTypes from 'prop-types';
import { VOUCHERS_ERRORS } from '@constants';
import { TextInput, Checkbox, Box } from '@components';
import MESSAGES from '@messages';
import { theme } from '../styled';
import Form from './Form';

const errorsMap = VOUCHERS_ERRORS;

const VoucherForm = ({
  setVoucher,
  voucherState,
  setVoucherState,
  voucherInputValue,
  setVoucherInputValue,
  isVoucherOk,
  setVoucherOk,
}) => (
  <Box>
    <Form
      url={`vouchers/${voucherInputValue}`}
      method="get"
      onSuccess={({ data, setMessage }) => {
        if (data.ok) {
          setMessage({
            success: MESSAGES.VOUCHER_CORRECT,
          });
          setVoucher(data);
          setVoucherOk(true);
          setTimeout(() => {
            setMessage({ success: '' });
          }, 2000);
        }
      }}
      onError={() => {
        setVoucher('');
      }}
      errorsMap={errorsMap}
      inline
      buttonProps={{
        px: theme.button.small.px,
        py: theme.button.small.py,
        maxWidth: '100px',
        color: 'yellow',
        display: voucherState ? 'block' : 'none',
        mt: 0,
        disabled: isVoucherOk,
      }}
    >
      {({ errors, setButtonText, clearMessages }) => {
        setButtonText(isVoucherOk ? MESSAGES.OK : MESSAGES.ADD);
        if (!voucherState) clearMessages('');
        return (
          <>
            <Checkbox
              name="hasVoucher"
              label={MESSAGES.I_HAVE_VOUCHER}
              value={voucherState}
              onChange={(nextValue) => {
                if (voucherState !== nextValue) {
                  setVoucherState(nextValue);
                  if (!nextValue) {
                    setVoucher('');
                    setVoucherOk(false);
                  }
                }
              }}
            />
            {voucherState && (
              <TextInput
                autocomplete="off"
                errors={errors}
                name="code"
                placeholder={MESSAGES.VOUCHER_CODE}
                required
                mb={0}
                mr={2}
                flex={1}
                value={voucherInputValue}
                onChange={(nextValue) => {
                  if (voucherInputValue !== nextValue) {
                    setVoucherInputValue(nextValue);
                    setVoucherOk(false);
                  }
                }}
              />
            )}
          </>
        );
      }}
    </Form>
  </Box>
);

VoucherForm.propTypes = {
  voucher: PropTypes.shape({
    code: PropTypes.string,
    ok: PropTypes.bool,
  }),
  setVoucher: PropTypes.func.isRequired,
  voucherState: PropTypes.string,
  setVoucherState: PropTypes.func,
  voucherInputValue: PropTypes.string,
  setVoucherInputValue: PropTypes.func,
  isVoucherOk: PropTypes.bool,
  setVoucherOk: PropTypes.func,
};

export default VoucherForm;
