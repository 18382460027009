import { isEmpty } from 'lodash';
import axios from 'axios';
import isBrowserFn from './isBrowser';

const TOKEN_KEY = 'jwtToken';
const CART_KEY = 'cart';
const VOUCHER_KEY = 'voucher';
const AS_CLIENT_HEADER = 'X-As-Client';

const { parse } = JSON;
const { stringify } = JSON;

const isBrowser = isBrowserFn();

const auth = {
  /**
  * Remove an item from the used storage
  * @param  {String} key [description]
  */
  clear(key) {
    if (!isBrowser) return null;

    if (localStorage && localStorage.getItem(key)) {
      return localStorage.removeItem(key);
    }

    if (sessionStorage && sessionStorage.getItem(key)) {
      return sessionStorage.removeItem(key);
    }

    return null;
  },

  /**
  * Clear all app storage
  */
  clearAppStorage() {
    if (!isBrowser) return;

    if (localStorage) {
      localStorage.clear();
    }

    if (sessionStorage) {
      sessionStorage.clear();
    }
  },

  clearToken(tokenKey = TOKEN_KEY) {
    this.setAxiosHeaders();
    auth.clearCart();
    return auth.clear(tokenKey);
  },

  clearCart(cartKey = CART_KEY) {
    return auth.clear(cartKey);
  },

  clearVoucher(voucherKey = VOUCHER_KEY) {
    return auth.clear(voucherKey);
  },

  isTokenInLocalStorage() {
    return isBrowser && !!localStorage && !!localStorage.getItem(TOKEN_KEY);
  },

  /**
  * Returns data from storage
  * @param  {String} key Item to get from the storage
  * @return {String|Object}     Data from the storage
  */
  get(key) {
    if (!isBrowser) return null;

    if (localStorage && localStorage.getItem(key)) {
      return parse(localStorage.getItem(key)) || null;
    }

    if (sessionStorage && sessionStorage.getItem(key)) {
      return parse(sessionStorage.getItem(key)) || null;
    }

    return null;
  },

  getToken(tokenKey = TOKEN_KEY) {
    return auth.get(tokenKey);
  },

  getCart(cartKey = CART_KEY) {
    return auth.get(cartKey);
  },

  getVoucher(voucherKey = VOUCHER_KEY) {
    return auth.get(voucherKey);
  },

  /**
  * Set data in storage
  * @param {String|Object}  value    The data to store
  * @param {String}  key
  * @param {Boolean} iTokensInLocalStorage  Defines if we need to store in localStorage or sessionStorage
  */
  set(value, key, isLocalStorage) {
    if (!isBrowser || isEmpty(value)) {
      return null;
    }

    if (isLocalStorage && localStorage) {
      return localStorage.setItem(key, stringify(value));
    }

    if (sessionStorage) {
      return sessionStorage.setItem(key, stringify(value));
    }

    return null;
  },

  setToken(value = '', isLocalStorage = false, tokenKey = TOKEN_KEY) {
    this.setAxiosHeaders(value);
    return auth.set(value, tokenKey, isLocalStorage);
  },

  setCart(cart = '', cartKey = CART_KEY) {
    const isLocalStorage = auth.isTokenInLocalStorage();
    return auth.set(cart, cartKey, isLocalStorage);
  },

  setVoucher(voucher = '', voucherKey = VOUCHER_KEY) {
    const isLocalStorage = auth.isTokenInLocalStorage();
    return auth.set(voucher, voucherKey, isLocalStorage);
  },

  setAxiosHeaders(token) {
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common.Authorization;
    }
  },

  setAsClientHeader(id) {
    if (id) {
      axios.defaults.headers.common[AS_CLIENT_HEADER] = id;
    } else {
      delete axios.defaults.headers.common[AS_CLIENT_HEADER];
    }
  },
};

export default auth;
