import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Link, withPrefix, graphql, StaticQuery,
} from 'gatsby';
import {
  List,
  Box,
  Flex,
  GridBox,
  Offcanvas,
  Container,
  // theme,
  UserActions,
} from '@components';
import { Item } from './NavbarStyles';
import LogoSVG from '../../assets/logo.inline.svg';

const Wrapper = styled.div`
  z-index: 1;

  .is-home-page & {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
  }

  ${(props) => props.isHomePage ? `
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
  ` : 'position: static !important;'}
`;

const Logo = styled(LogoSVG)`
    display: block;
    margin: auto;
    max-width: 170px;
    padding: 0 1em;
    margin-left: -1em;
    width: 100%;
    z-index: 1;
`;

const StyledGridBox = styled(GridBox)`
  position: relative;
  z-index: 1;
`;

// const Rect = () => (
//   <svg
//     width="450"
//     height="474"
//     viewBox="0 0 450 474"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <rect
//       x="213.859"
//       width="280"
//       height="380"
//       transform="rotate(34.2329 213.859 0)"
//       fill="currentColor"
//     />
//   </svg>
// );

// const YellowRectangle = styled.div`
//   color: ${theme.colors.yellow};
//   transform: translate(-27%, -66%);
//   opacity: 0.07;
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: -1;
// `;

// const WhiteRectangle = styled.div`
//   color: ${theme.colors.bg};
//   transform: translate(-27, -66%);
//   opacity: 1;
//   position: absolute;
//   top: 0;
//   left: 0;
//   z-index: -1;
// `;

const Navbar = ({ toggleNavbar, isActive, location: { pathname } }) => (

  <StaticQuery
    query={graphql`
          query SearchIndexQuery {
              site {
                siteMetadata {
                  title
                  menu {
                    label
                    path
                  }
                }
              }
          }
      `}
    render={({ site }) => {
      const { menu } = site.siteMetadata;
      const isHomepage = pathname === withPrefix('/');

      return (
        <Wrapper isHomePage={isHomepage}>
          <Container py={0}>
            <Flex
              alignItems="center"
              justifyContent="space-between"
            >
              <StyledGridBox py={0} px={0}>
                <Link to="/">
                  <Logo />
                </Link>
                {/* <YellowRectangle>
              <Rect />
            </YellowRectangle> */}
              </StyledGridBox>
              <Box display={['block', 'block', 'none', 'none']}>
                <Offcanvas
                  isActive={isActive}
                  toggle={toggleNavbar}
                  items={menu}
                />
              </Box>
              <Box display={['none', 'none', 'block', 'block']} mr={[0, 0, '-1em']}>
                <List variant="inline">
                  {menu.filter(({ path }) => path !== '/').map(({ label, path }) => (
                    <Item key={path}>
                      <Link
                        to={path}
                        activeClassName="active"
                        onClick={() => { toggleNavbar(false); }}
                      >
                        {label}
                      </Link>
                    </Item>
                  ))}
                </List>
              </Box>
            </Flex>
            <UserActions pathname={pathname} />
          </Container>
        </Wrapper>
      );
    }}
  />
);

Navbar.propTypes = {
  toggleNavbar: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default Navbar;
