import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import {
  Box,
  List,
  Hamburger,
} from '@components';
import {
  NavbarFlexWrapper,
} from './OffcanvasStyles';

class Offcanvas extends Component {
  UNSAFE_componentWillReceiveProps({ isActive }) {
    if (isActive === this.props.isActive) {
      return;
    }

    // eslint-disable-next-line no-undef
    document.body.setAttribute(
      'style',
      `overflow: ${isActive ? 'hidden' : 'auto'}`
    );
  }

  render() {
    const {
      toggle,
      isActive,
      items,
    } = this.props;

    return (
      <NavbarFlexWrapper className={isActive ? 'active' : ''}>
        <Box>
          <Box
            className="backdrop"
            onClick={() => { toggle(false); }}
          />
          <List className="list">
            {items.map(({ label, path }) => (
              <li
                className="item"
                key={path}
              >
                <Link
                  className="link"
                  to={path}
                  activeClassName="active"
                  partiallyActive={path !== '/'}
                  onClick={() => {
                    toggle(false);
                  }}
                >
                  {label}
                </Link>
              </li>
            ))}
          </List>
          <Hamburger
            className="hamburger"
            isActive={isActive}
            toggle={toggle}
          />
        </Box>
      </NavbarFlexWrapper>
    );
  }
}

Offcanvas.propTypes = {
  toggle: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    path: PropTypes.string,
  })),
};

export default Offcanvas;
