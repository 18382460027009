import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '@components';
import { phoneValidator } from '@validators';
import { UserContext } from '@helpers';
import MESSAGES from '@messages';
import Form from './Form';

const errorsMap = {
  'wrong.phone': MESSAGES.WRONG_PHONE_NUMBER,
  'wrong.address_street': MESSAGES.WRONG_ADDRESS_STREET,
  'wrong.address_postal_code': MESSAGES.POSTAL_CODE,
};

const url = '/client';

const UserDataForm = ({ closePortal }) => {
  const { setClient, client: { fields: { hit = {} } = {} } = {} } = useContext(UserContext);

  return (
    <Form
      url={url}
      buttonText={MESSAGES.SAVE}
      title={MESSAGES.USER_DATA}
      onSuccess={({ data, setMessage }) => {
        const { ok, client } = data;
        if (ok) {
          setMessage(
            { success: MESSAGES.SAVED },
            () => {
              setClient(client);
            }
          );
          setTimeout(closePortal, 1000);
        }
      }}
      errorsMap={errorsMap}
    >
      {({ errors }) => (
        <>
          <TextInput
            errors={errors}
            name="first_name"
            placeholder={MESSAGES.YOUR_FIRST_NAME}
            required
            value={hit.first_name}
          />
          <TextInput
            errors={errors}
            name="last_name"
            placeholder={MESSAGES.YOUR_LAST_NAME}
            required
            value={hit.last_name}
          />
          <TextInput
            errors={errors}
            name="phone"
            placeholder={MESSAGES.PHONE_NUMBER}
            validators={[phoneValidator(MESSAGES.WRONG_PHONE_NUMBER)]}
            required
            value={hit.phone}
          />
          <TextInput
            errors={errors}
            name="address_street"
            placeholder={MESSAGES.ADDRESS_STREET}
            required
            value={hit.address_street}
          />
          <TextInput
            errors={errors}
            name="address_postal_code"
            placeholder={MESSAGES.POSTAL_CODE}
            required
            value={hit.address_postal_code}
          />
          <TextInput
            errors={errors}
            name="address_city"
            placeholder={MESSAGES.CITY}
            required
            value={hit.address_city}
          />
        </>
      )}
    </Form>
  );
};

UserDataForm.propTypes = {
  closePortal: PropTypes.func,
};

export default UserDataForm;
