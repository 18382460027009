import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { auth, CartContext, UserContext } from '@helpers';

const label = 'Wybierz klienta';

const StyledLabel = styled.label`
  font-size: 0.6em;
  max-width: 24em;

  select {
    padding: 0.25em 0.5em;
  }
`;

const SmallText = styled.span`
`;

const CashClientsSelect = ({
  initialValue = '',
  onChange,
  cashClients,
  isSuperUser,
  fetchClient,
}) => {
  const [value, setValue] = useState(initialValue);
  const { forceClearCart } = useContext(CartContext);
  const { clearUpcomming } = useContext(UserContext);

  useEffect(() => {
    if (onChange) onChange(value);

    forceClearCart();
    clearUpcomming();

    auth.setAsClientHeader(value);
    fetchClient();
  }, [value, onChange, fetchClient, clearUpcomming, forceClearCart]);

  if (!isSuperUser || !cashClients) return null;

  return (
    <StyledLabel htmlFor="cash-clients">
      <SmallText>{label}</SmallText>
      <select
        id="cash-clients"
        onChange={(e) => {
          const nextValue = +e.target.value;
          setValue(nextValue);
        }}
        value={value}
        className={!value ? 'error' : ''}
      >
        <option value="">{label}</option>
        {cashClients.map(({ id, name }) => (
          <option key={id} value={id}>{name}</option>
        ))}
      </select>
    </StyledLabel>
  );
};

CashClientsSelect.propTypes = {
  onChange: PropTypes.func,
  initialValue: PropTypes.string,
  cashClients: PropTypes.arrayOf(PropTypes.shape({})),
  isSuperUser: PropTypes.bool,
  fetchClient: PropTypes.func,
};

export default CashClientsSelect;
