import styled from 'styled-components';
import { theme } from '@components';

export const Item = styled.li`
transition: ${theme.transition.set('background')};

  a {
    display: block;
    padding: 1em;
    transition: ${theme.transition.set('color')};
    border: none;

    &, &:visited {
      color: ${theme.colors.text};
    }


    &:hover {
      color: ${theme.colors.blue};
      background: transparent;
      border: none;
    }

    &.active {
      cursor: default;

      &, &:hover {
        color: ${theme.colors.yellow};
      }
    }
  }
`;
