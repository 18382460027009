import cartItemHasPaidParticipants from './cartItemHasPaidParticipants';

const getCart = ({
  cartItems = [], flattenEvents = [], prices = {}, voucher = {}, upcommingMonthgroups = [],
}) => {
  const { once_a_week, few_times_a_week } = prices;

  const groups = cartItems.reduce((acc, item) => {
    const {
      agenda_id, id, participant, from_now,
    } = item;
    if (!agenda_id || !participant || !id) return acc;

    const foundItem = flattenEvents.find((event) => event.id === id);

    if (!foundItem) return acc;

    const paid = !!cartItemHasPaidParticipants({ participant, id }, foundItem);
    const foundInUpcommingMonthgroups = upcommingMonthgroups.length && upcommingMonthgroups.some((el) => id === el.id);

    const sameItems = cartItems.filter((el) => id === el.id);
    const subtractVacancies = sameItems.length > 0 ? sameItems.length - 1 : 0;
    const computedVacancies = foundItem.vacancies - subtractVacancies;

    let vacancies = computedVacancies;
    if (vacancies <= 0 && foundInUpcommingMonthgroups) {
      vacancies = 1;
    }

    const fullItem = {
      ...foundItem,
      participant,
      paid: foundInUpcommingMonthgroups ? false : paid,
      from_now,
      vacancies,
    };

    const month = foundItem.month || '';

    const key = `${agenda_id}_${participant}_${month}`;

    if (Array.isArray(acc[key])) {
      acc[key].push(fullItem);
    } else {
      acc[key] = [fullItem];
    }

    return acc;
  }, {});

  const items = Object.values(groups).reduce((acc, group) => {
    const unitPrice = group.length > 1 ? few_times_a_week : once_a_week;

    group.forEach((item) => {
      acc.push({
        ...item,
        unitPrice,
      });
    });

    return acc;
  }, []);

  const itemsAmount = items.reduce((acc, {
    from_now, unitPrice, events_length, events_from_now_length,
  }) => {
    const eventsLength = from_now ? events_from_now_length : events_length;
    return acc + (unitPrice * eventsLength);
  }, 0);

  let amount = itemsAmount;
  if (voucher.amount) {
    amount -= Math.abs(voucher.amount);
  }

  if (Number.isNaN(amount)) return { items, amount: 'x' };

  return { items, amount };
};

export default getCart;
