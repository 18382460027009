import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MESSAGES from '@messages';
import Amount from './Amount';
import VoucherRow from './VoucherRow';
import { theme } from './styled';
import CartClassesCell from './CartClassesCell';

const Table = styled.table`
  width: 100%;
  font-size: 0.8em;

  tr.error {
    td {
      background: ${theme.colors.redLight};
    }
  }
`;

const ClearButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 1.5rem;
  color: ${theme.colors.textLight};

  &:hover,
  &:focus {
    outline: none;
    color: ${theme.colors.red};
  }
`;

const ErrorText = styled.div`
  color: ${theme.colors.red};
  font-weight: 600;
`;

const getAmount = (amount, voucher) => {
  const { amount: voucherAmount = 0 } = voucher || {};
  // plus because voucherAmount is negative
  return Math.max(amount + voucherAmount, 0);
};

const CartTable = ({
  items,
  amount,
  participants,
  removeFromCart,
  voucher,
}) => (
  <>
    <Table>
      <thead>
        <tr>
          <th>{MESSAGES.CLASS}</th>
          <th>{MESSAGES.PARTICIPANT}</th>
          <th>{MESSAGES.AMOUNT_TIMES_PRICE}</th>
          <th> </th>
        </tr>
      </thead>
      <tbody>
        {items.map((item) => {
          const { name: participantName } = participants.find((p) => p.id === item.participant) || {};
          const {
            paid,
            vacancies,
            from_now,
            events_length,
            events_from_now_length,
            unitPrice = 0,
          } = item;
          const noVacancies = vacancies <= 0;
          const hasError = paid || noVacancies;

          const eventsLength = (from_now ? events_from_now_length : events_length) || 0;

          const price = eventsLength * item.unitPrice;

          return (
            <tr
              key={`${item.id}-${item.participant}`}
              className={hasError ? 'error' : ''}
            >
              <td>
                <CartClassesCell {...item} />
              </td>
              <td>{participantName}</td>
              <td>
                {item.from_now && (
                  <div>
                    {' '}
                    {MESSAGES.FROM_TODAY}
                  </div>
                )}
                {eventsLength}
                {' '}
                x
                {' '}
                {Number.isNaN(unitPrice) ? 'x' : unitPrice }
                {MESSAGES.PLN}
                {' = '}
                <strong>
                  {Number.isNaN(price) ? 'x' : price}
                  {' '}
                  {MESSAGES.PLN}
                </strong>
                {hasError && (
                  <ErrorText>
                    {paid
                      ? MESSAGES.CLASSES_ALREADY_PAID
                      : `${MESSAGES.VACANCIES_LEFT}: ${vacancies}`}

                  </ErrorText>
                )}

              </td>
              <td>
                <ClearButton
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    removeFromCart(item);
                  }}
                >
                  &times;
                </ClearButton>
              </td>
            </tr>
          );
        })}
        <VoucherRow voucher={voucher} />
      </tbody>
    </Table>
    <Amount>
      {getAmount(amount, voucher)}
    </Amount>
  </>
)
;

CartTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  participants: PropTypes.arrayOf(PropTypes.object),
  amount: PropTypes.number,
  removeFromCart: PropTypes.func,
  checkPaidItem: PropTypes.func,
  voucher: PropTypes.shape({}),
};

export default CartTable;
