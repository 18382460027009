import React, { Component } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Relative } from '@components';
import MESSAGES from '@messages';
import { startOfWeek, addDays, toTimeString } from './helpers';
import CalendarCell from './CalendarCell';
import CalendarRow from './CalendarRow';
import TimeCell from './TimeCell';
import HeaderCell from './HeaderCell';
import EventCell from './EventCell';
import theme from '../styled/theme';

const Wrapper = styled.div`
  min-width: ${theme.calendar.minWidth};
`;

const Inner = styled.div`
  /* overflow: auto; */
`;

const getTime = (timeStr) => {
  const [hours, minutes] = timeStr.split(':');
  return [+hours, +minutes];
};

const pad = (num) => (`0${num}`).substr(-2);

const BLOCK_SIZE = 15;
const roundBlockSize = (num) => pad(+num - (+num % BLOCK_SIZE));

const roundTime = (time) => {
  const [h, m] = getTime(time);
  return `${h}:${roundBlockSize(m)}`;
};

const isCollide = (aStart, aEnd, bStart, bEnd) => {
  const aDiff = aEnd - aStart - 100;
  const bDiff = bEnd - bStart - 100;

  return !(((aStart + aDiff) <= bStart) || (aStart > (bStart + bDiff)));
};

const monday = startOfWeek(new Date());
const timeToNum = (str) => str ? +str.replace(':', '') : '';

const getOverlaps = (current, arr) => arr
  .filter(({ start_time, end_time }) => {
    const currentStart = timeToNum(current.start_time);
    const currentEnd = timeToNum(current.end_time);

    const nextStart = timeToNum(start_time);
    const nextEnd = timeToNum(end_time);

    const collide = isCollide(currentStart, currentEnd, nextStart, nextEnd);

    return collide
        || (currentStart <= nextStart && currentEnd >= nextEnd + nextStart)
        || (currentStart >= nextStart && currentEnd <= nextEnd);
  })
  // proper order
  .reverse();

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.timeBlocks = {};
    this.normalizeTimeBlocks(props.events);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.events !== nextProps.events) {
      this.normalizeTimeBlocks(nextProps.events);
    }
  }

    normalizeTimeBlocks = (events) => {
      if (!Array.isArray(events) || events.length < 1) return;
      const timeBlocks = {};

      const { firstHour, lastHour } = events.reduce((acc, cur) => {
        cur.forEach((dayEvents) => {
          // because dayEvents contains array of all upcomming events
          // we need to pull out only first one
          const [firstItem] = dayEvents;
          const [currentStart] = getTime(firstItem.start_time);
          const [currentEnd] = getTime(firstItem.end_time);

          // eslint-disable-next-line no-param-reassign
          acc = {
            firstHour: currentStart < acc.firstHour ? currentStart : acc.firstHour,
            lastHour: currentEnd > acc.lastHour ? currentEnd : acc.lastHour,
          };
        });
        return acc;
      }, { firstHour: 23, lastHour: 0 });

      // generate time blocks from firstHour to lastHour
      for (let hour = firstHour; hour < lastHour; hour += 1) {
        for (let minutes = 0; minutes < 60; minutes += BLOCK_SIZE) {
          // TODO: round time to timeBlock
          const timeString = toTimeString(hour, minutes);
          timeBlocks[timeString] = Array.from({ length: 7 }, () => []);
        }
      }

      if (events && Array.isArray(events)) {
        events.forEach((dayEvents, dayIndex) => {
          if (Array.isArray(dayEvents)) {
            const currentMonthDayEvents = dayEvents.map((items) => {
              const [currentMonth] = items || [];
              return currentMonth;
            });

            dayEvents.forEach((currentTimeEvents) => {
              const [event] = currentTimeEvents || [];
              if (!event) return;

              const { start_time, end_time } = event;

              // TODO: Round minutes
              const start = timeToNum(start_time);
              const end = timeToNum(end_time);

              const overlaps = getOverlaps(event, currentMonthDayEvents);

              const time = roundTime(start_time);

              const blockSpan = (((end - start) + 5) / 100) * (60 / BLOCK_SIZE);
              timeBlocks[time][dayIndex].push({
                event: { ...event, blockSpan, overlaps }, events: currentTimeEvents,
              });
            });
          }
        });

        this.timeBlocks = timeBlocks;
      }
    };

    render() {
      const { events = [] } = this.props;

      const timeBlockEntries = Object.entries(this.timeBlocks);

      if (!timeBlockEntries.length) return MESSAGES.NO_RESULTS;

      return (
        <Wrapper className="calendar">
          <Inner>
            <CalendarRow>
              <HeaderCell className="calendar__cell--time-col" />
              <CalendarCell timeSpacing />
              {events.slice(1, 7).map((el, index) => {
                const day = addDays(monday, index);
                return <HeaderCell key={day} day={day} />;
              })}
            </CalendarRow>

            <Relative>
              {timeBlockEntries.map(([time, days]) => (
                <CalendarRow key={time}>
                  <TimeCell>{time}</TimeCell>
                  <EventCell timeSpacing />
                  {days.slice(1, 7).map((items, index) => (
                    <EventCell
                      isNearRight={index > 2}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${time}-${index}`}
                      items={items}
                      weekend={index >= 5}
                    />
                  ))}
                </CalendarRow>
              ))}
            </Relative>
          </Inner>
        </Wrapper>
      );
    }
}


Calendar.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({})
      )
    )
  ),
};

export default Calendar;
