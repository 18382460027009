import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '@components';
import MESSAGES from '@messages';
import { UserContext } from '@helpers';
import Form from './Form';

const errorsMap = {
  limit_reached: MESSAGES.PARTICIPANTS_LIMIT_REACHED,
  'wrong.age': MESSAGES.WRONG_AGE,
};

const ParticipantForm = ({ closePortal, setValue }) => {
  const { client, addParticipant } = useContext(UserContext);
  const delayedClose = () => setTimeout(closePortal, 1000);

  if (!client) return null;

  return (
    <Form
      url="/participants"
      buttonText={MESSAGES.ADD}
      title={MESSAGES.ADDING_PARTICIPANT_TITLE}
      onSuccess={({ data, setMessage }) => {
        if (data) {
          setMessage(
            { success: MESSAGES.SUCCESSFULLY_ADDED },
            () => {
              addParticipant(data);
              setValue(data.id);
              delayedClose();
            }
          );
        }
      }}
      errorsMap={errorsMap}
    >
      {({ errors }) => (
        <>
          <TextInput
            errors={errors}
            name="first_name"
            placeholder={MESSAGES.FIRST_NAME}
            required
          />
          <TextInput
            errors={errors}
            name="last_name"
            placeholder={MESSAGES.LAST_NAME}
            required
          />
          <TextInput
            errors={errors}
            name="age"
            placeholder={MESSAGES.AGE}
            type="number"
            required
          />
        </>
      )}
    </Form>
  );
};

ParticipantForm.propTypes = {
  closePortal: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
};

export default ParticipantForm;
