import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { DAY_NAMES_SHORT } from '@constants';
import theme from '../styled/theme';
import CalendarCell from './CalendarCell';

const Wrapper = styled(CalendarCell)`
    text-align: center;
    height: ${theme.calendar.headerHeight};
    display: flex;
    flex-direction: column;
    justify-content: center;

    ${(props) => props.isToday && `
        color: ${theme.colors.yellow};
    `}
`;

const HeaderCell = (props) => {
  const { day } = props;
  const isToday = day && day.toDateString() === new Date().toDateString();

  return (
    <Wrapper {...props} isToday={isToday}>
      {day && (
        <>
          <div>{DAY_NAMES_SHORT[day.getDay()]}</div>
          {/* <div>{day.getDate()}</div> */}
        </>
      )}
    </Wrapper>
  );
};

HeaderCell.propTypes = {
  day: PropTypes.instanceOf(Date),
};

export default HeaderCell;
