import styled from 'styled-components';
import theme from '../styled/theme';

const CalendarCell = styled.div`
    flex: 1;
    height: ${theme.calendar.height};
    
    ${(props) => props.timeSpacing && `
        flex: 0;
    `};
`;

export default CalendarCell;
