import styled from 'styled-components';
import theme from '../styled/theme';
import CalendarCell from './CalendarCell';

const TimeCell = styled(CalendarCell)`
    line-height: 1em;
    transform: translateY(-50%);
    font-size: 0.9em;
    font-weight: 300;
    color: ${theme.colors.textLight};
    /* color: $text-muted; */
    display: flex;
    align-items: center;
    justify-content: center;

    ${(props) => props.col && `
        flex: 1em 0 0;
    `}
`;

export default TimeCell;
