import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { UpcommingTable, GridFlex, Button } from '@components';
import { UserContext, CalendarContext, CartContext } from '@helpers';
import MESSAGES from '@messages';
import styled from 'styled-components';
import { theme } from '../styled';

const CashInfo = styled.p`
  color: ${theme.colors.red};
  text-align: center;
  width: 100%;
`;

const CartForm = ({ closePortal }) => {
  const {
    isLogged,
    client: { participants = {}, is_cash_only } = {},
    upcommingMonthgroups,
    isSuperUser,
  } = useContext(UserContext);
  const { flattenEvents } = useContext(CalendarContext);
  const { addToCart } = useContext(CartContext);
  const ids = upcommingMonthgroups.map(({ id }) => id);


  const items = flattenEvents.filter(({ id }) => ids.includes(id)).map((item) => {
    const { participant } = upcommingMonthgroups.find((el) => el.id === item.id) || {};
    return { ...item, participant };
  });

  if (items.length <= 0) return null;

  const showButton = isLogged && (!is_cash_only || isSuperUser);

  return (
    <GridFlex
      p={0}
      position="relative"
    >
      <p>
        {MESSAGES.UPCOMMING_MONTHGROUPS_INFO}
      </p>
      <UpcommingTable
        participants={participants}
        items={items}
      />
      {showButton ? (
        <Button
          fullWidth
          onClick={(e) => {
            e.preventDefault();
            addToCart(upcommingMonthgroups);
            closePortal();
          }}
        >
          {MESSAGES.ADD_TO_CART}
        </Button>
      ) : (
        <CashInfo>
          {MESSAGES.CASH_ONLY_PAYMENT_INFO}
        </CashInfo>
      )}
    </GridFlex>
  );
};

CartForm.propTypes = {
  closePortal: PropTypes.func,
};

export default CartForm;
